import api from "../../../services/api";

type LinkUserToCompanyParams = {
  userId: number;
  companyId: number;
  axiosAuthToken: object;
}

export default function({ userId, companyId, axiosAuthToken }: LinkUserToCompanyParams): Promise<void> {
  const putData = {
    "user_id": userId,
    "company_id": companyId
  };

  return new Promise<void>((resolve, reject) => {
    api.put<void>('users/link-user-to-company', putData, axiosAuthToken)
      .then(() => {
        resolve();
      })
      .catch(reject);
  });
}
