import {UserSearchResponseType} from "../../../types/UserSearchResponseType";
import api from "../../../services/api";

type FindUserByEmailParams = {
  email: string;
  axiosAuthToken: object;
}

export default function({ email, axiosAuthToken }: FindUserByEmailParams): Promise<UserSearchResponseType> {
  return new Promise<UserSearchResponseType>((resolve, reject) => {
    api.post<UserSearchResponseType>('users/search-user', { email }, axiosAuthToken)
      .then(({data}) => {
        resolve(data);
      })
      .catch(reject);
  });
}
